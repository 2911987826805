import React from "react";
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material";
import { deepOrange, grey } from "@mui/material/colors";

import "./App.scss";

import AppRouter from "./AppRouter";

import moment from "moment-timezone";
moment.tz.setDefault("Asia/Singapore");

export default function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <ThemeProvider theme={customTheme()}>
          <AppRouter />
        </ThemeProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}

function customTheme() {
  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
      },
    },
    typography: {
      fontFamily: `"Outfit Variable", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    palette: {
      white: {
        light: grey[50],
        main: grey[100],
        dark: grey[200],
        contrastText: "black",
      },
      black: {
        light: grey[700],
        main: grey[800],
        dark: grey[900],
        contrastText: "white",
      },
      deepOrange: {
        light: deepOrange[700],
        main: deepOrange[800],
        dark: deepOrange[900],
        contrastText: "white",
      },
    },
  });
}
