import { Route, Routes } from "react-router-dom";

import Homepage from "./modules/homepage/Homepage";
import MusicClinic from "./modules/music-clinic/MusicClinic";
import MusicClinicJoiners from "./modules/music-clinic-joiners/MusicClinicJoiners";

function AppRouter() {
  return (
    <Routes>
      <Route exact path="/" element={<Homepage />} />
      <Route exact path="/music-clinic" element={<MusicClinic />} />
      <Route
        exact
        path="/music-clinic/joiners"
        element={<MusicClinicJoiners />}
      />
    </Routes>
  );
}

export default AppRouter;
