import { useCallback, useEffect, useState } from "react";
import FirebaseService from "../../services/FirebaseService";

import { Box, Container, Grid, Typography } from "@mui/material";

import "./MusicClinicJoiners.scss";

export default function MusicClinicJoiners() {
  const [users, setUsers] = useState([]);

  const handleListUsers = useCallback(() => {
    FirebaseService.all("users")
      .then((response) => {
        setUsers(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    handleListUsers();
  }, [handleListUsers]);

  return (
    <Container sx={{ py: 4 }}>
      <Box sx={{ p: 4, border: "1px solid black", borderRadius: 2 }}>
        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "2rem" }}>Students</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{ flex: "1 1 0", fontWeight: "bold" }}
                textAlign={"left"}
              >
                Name
              </Typography>
              <Typography
                sx={{ flex: "1 1 0", fontWeight: "bold" }}
                textAlign={"left"}
              >
                Instrument
              </Typography>
              <Typography
                sx={{ flex: "1 1 0", fontWeight: "bold" }}
                textAlign={"left"}
              >
                Email Address
              </Typography>
              <Typography
                sx={{ flex: "1 1 0", fontWeight: "bold" }}
                textAlign={"left"}
              >
                Mobile
              </Typography>
              <Typography
                sx={{ flex: "1 1 0", fontWeight: "bold" }}
                textAlign={"left"}
              >
                Join Date
              </Typography>
            </Box>
          </Grid>
          {users
            ?.filter((i) => i.type === "student")
            .sort((a, b) => b.join_date_unix - a.join_date_unix)
            .map((i, iKey) => (
              <Grid
                item
                xs={12}
                key={iKey}
                sx={{ borderBottom: "1px solid black" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    textAlign={"left"}
                    sx={{
                      flex: "1 1 0",
                      fontWeight: { xs: "bold", md: "normal" },
                      textTransform: "capitalize",
                    }}
                  >
                    {i.first_name} {i.last_name}
                  </Typography>
                  <Typography sx={{ flex: "1 1 0" }} textAlign={"left"}>
                    {i.instrument}
                  </Typography>
                  <Typography sx={{ flex: "1 1 0" }} textAlign={"left"}>
                    {i.email_address}
                  </Typography>
                  <Typography sx={{ flex: "1 1 0" }} textAlign={"left"}>
                    {i.mobile_number}
                  </Typography>
                  <Typography sx={{ flex: "1 1 0" }} textAlign={"left"}>
                    {i.join_date}
                  </Typography>
                </Box>
              </Grid>
            ))}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "2rem" }}>Teachers</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{ flex: "1 1 0", fontWeight: "bold" }}
                textAlign={"left"}
              >
                Name
              </Typography>
              <Typography
                sx={{ flex: "1 1 0", fontWeight: "bold" }}
                textAlign={"left"}
              >
                Instrument
              </Typography>
              <Typography
                sx={{ flex: "1 1 0", fontWeight: "bold" }}
                textAlign={"left"}
              >
                Email Address
              </Typography>
              <Typography
                sx={{ flex: "1 1 0", fontWeight: "bold" }}
                textAlign={"left"}
              >
                Mobile
              </Typography>
              <Typography
                sx={{ flex: "1 1 0", fontWeight: "bold" }}
                textAlign={"left"}
              >
                Join Date
              </Typography>
            </Box>
          </Grid>
          {users
            ?.filter((i) => i.type === "teacher")
            .sort((a, b) => b.join_date_unix - a.join_date_unix)
            .map((i, iKey) => (
              <Grid
                item
                xs={12}
                key={iKey}
                sx={{ borderBottom: "1px solid black" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    textAlign={"left"}
                    sx={{
                      flex: "1 1 0",
                      fontWeight: { xs: "bold", md: "normal" },
                      textTransform: "capitalize",
                    }}
                  >
                    {i.first_name} {i.last_name}
                  </Typography>
                  <Typography sx={{ flex: "1 1 0" }} textAlign={"left"}>
                    {i.instrument}
                  </Typography>
                  <Typography sx={{ flex: "1 1 0" }} textAlign={"left"}>
                    {i.email_address}
                  </Typography>
                  <Typography sx={{ flex: "1 1 0" }} textAlign={"left"}>
                    {i.mobile_number}
                  </Typography>
                  <Typography sx={{ flex: "1 1 0" }} textAlign={"left"}>
                    {i.join_date}
                  </Typography>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Container>
  );
}
