import { Box, Container, Typography } from "@mui/material";

import "./Homepage.scss";

export default function Homepage() {
  return (
    <Box component={"section"} id="coming-soon">
      <Container className="coming-soon-container">
        <Box className="jumbotron-box">
          <Typography className="lead">
            A website is soon to rise,{" "}
            <Box component={"span"} className="church-name">
              G.R.A.C.E.
            </Box>{" "}
            Christian&nbsp;Fellowship
          </Typography>
        </Box>
        <Box className="verse-box">
          <Typography className="verse-content">
            because, if you confess with your mouth that Jesus is Lord and
            believe in your heart that God raised him from the dead, you will be
            saved.
          </Typography>
          <Typography className="verse-ref">Romans 10:9</Typography>
        </Box>
      </Container>
    </Box>
  );
}
