import React, { useState } from "react";

import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";

import "./MusicClinic.scss";
import FirebaseService from "../../services/FirebaseService";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase";
import moment from "moment";

export default function MusicClinic() {
  const [snackbar, setSnackbar] = useState({
    x: "right",
    y: "top",
    open: false,
    duration: 6000,
    text: "",
    severity: "succes",
  });

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    type: "",
    instrument: "",
    password: "Biyaya2024",
  });

  const userTypes = [
    {
      value: "teacher",
      display: "Teach",
    },
    {
      value: "student",
      display: "Learn",
    },
  ];
  const instruments = ["Guitar", "Piano", "Drums", "Bass", "Vocals"];

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setUser((user) => ({ ...user, [name]: value }));
  };

  const handleEnterKey = (key) => {
    if (key === "Enter") {
      handleJoin();
    }
  };

  const handleJoin = () => {
    if (user.firstName === "" || user.lastName === "") {
      setSnackbar((snackbar) => ({
        ...snackbar,
        open: true,
        text: "All info is required",
        severity: "error",
      }));

      return;
    }

    createUserWithEmailAndPassword(auth, user.email, user.password)
      .then(async (response) => {
        let date = moment();

        setSnackbar((snackbar) => ({
          ...snackbar,
          open: true,
          text: "Schedules will be sent soon!",
          severity: "success",
        }));

        await FirebaseService.insert("users", {
          first_name: user.firstName,
          last_name: user.lastName,
          email_address: user.email,
          mobile_number: user.mobile,
          type: user.type,
          instrument: user.instrument,
          join_date: date.format("YYYY-MM-DD"),
          join_date_unix: date.unix(),
        });
      })
      .catch((error) => {
        console.error(error);

        if (error.code.includes("email-already-in-use")) {
          setSnackbar((snackbar) => ({
            ...snackbar,
            open: true,
            text: "Email is registered",
            severity: "error",
          }));
        } else if (
          error.code.includes("missing-email") ||
          error.code.includes("invalid-email")
        ) {
          setSnackbar((snackbar) => ({
            ...snackbar,
            open: true,
            text: "Correct email address please",
            severity: "error",
          }));
        } else {
          setSnackbar((snackbar) => ({
            ...snackbar,
            open: true,
            text: "Oops! Something went wrong",
            severity: "error",
          }));
        }
      });
  };

  return (
    <React.Fragment>
      <Box component={"section"} id="music-clinic">
        <Container className="music-clinic-container">
          <Box className="jumbotron">
            <Typography className="lead">Join Our Music Workshop!</Typography>
          </Box>
          <Box className="join-form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="First name"
                  variant="outlined"
                  autoComplete="off"
                  autoCapitalize="none"
                  name="firstName"
                  value={user.firstName}
                  onChange={(event) => handleOnChange(event)}
                  onKeyUp={(event) => handleEnterKey(event.code)}
                  color="black"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Last name"
                  variant="outlined"
                  autoComplete="off"
                  autoCapitalize="none"
                  name="lastName"
                  value={user.lastName}
                  onChange={(event) => handleOnChange(event)}
                  onKeyUp={(event) => handleEnterKey(event.code)}
                  color="black"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email address"
                  variant="outlined"
                  autoComplete="off"
                  autoCapitalize="none"
                  name="email"
                  value={user.email}
                  onChange={(event) => handleOnChange(event)}
                  onKeyUp={(event) => handleEnterKey(event.code)}
                  color="black"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Mobile number"
                  variant="outlined"
                  autoComplete="off"
                  autoCapitalize="none"
                  name="mobile"
                  value={user.mobile}
                  onChange={(event) => handleOnChange(event)}
                  onKeyUp={(event) => handleEnterKey(event.code)}
                  color="black"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="type-select-label">I want to</InputLabel>
                  <Select
                    labelId="type-select-label"
                    id="type-select"
                    value={user.type}
                    label="I want to"
                    onChange={(event) =>
                      setUser((user) => ({ ...user, type: event.target.value }))
                    }
                  >
                    {userTypes.map((i, iKey) => (
                      <MenuItem key={iKey} value={i.value}>
                        {i.display}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="instrument-select-label">
                    Instrument
                  </InputLabel>
                  <Select
                    labelId="instrument-select-label"
                    id="instrument-select"
                    value={user.instrument}
                    label="Instrument"
                    onChange={(event) =>
                      setUser((user) => ({
                        ...user,
                        instrument: event.target.value,
                      }))
                    }
                  >
                    {instruments.map((i, iKey) => (
                      <MenuItem key={iKey} value={i.toLowerCase()}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  className="submit-button"
                  color="deepOrange"
                  onClick={() => handleJoin()}
                >
                  Join
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: snackbar.y, horizontal: snackbar.x }}
        autoHideDuration={snackbar.duration}
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.text}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
